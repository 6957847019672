import React from "react"
// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"
import UnsubscribeForm from "@components/form/Form.Unsubscribe.jsx"

const unsubscribe = () => (
    <Layout addedClass="page--unsubscribe">
        <Seo title="Unsubscribe | LUPKYNIS&reg; (voclosporin)" description="We're sorry to see you go. Unsubscribe here if you would no longer like to receive updates and information about LUPKYNIS. See full Safety and Prescribing Information, and BOXED WARNING." canonicalURL="https://www.lupkynis.com/unsubscribe" />
        <Hero addedClass="bg-light-green hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">You're Leaving?</h1>
                        <h2>We're sorry to see you go</h2>
                        <p>Simply enter your email address below to unsubscribe from marketing communications from LUPKYNIS.</p>
                        <p className="legend">* = required field</p>
                        <UnsubscribeForm />
                    </div>
                    <div className="hero__image">
                        <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                    </div>
                </div>
            </div>
        </Hero>
    </Layout>
)

export default unsubscribe