import useForm from './useForm.js';
import React, { useCallback, useEffect } from "react";
import validate from "../../utils/util-validator";
import ruleSet from './ruleSet.js';
import { navigate } from "gatsby";
import Anchor from "@components/content/anchor.jsx"
// Style Imports
import "./Form.scss"
// Utility Imports
import { getVisitorId, getApiEndpoint } from '@src/helper';

// Content Imports


const Unsubscribe = () => {

    const success = useCallback((vals) => {
        const submitButton = document.querySelector("button[type='submit']");
        submitButton.classList.add('button--submitting');
        fetch(getApiEndpoint() + 'consumer/unsub', {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(vals),
        })
        .then(async (resp) => {
            var r = await resp.json();
            submitButton.classList.remove('button--submitting');
            if (r.success) {
                gtmFormSuccess();
                gtmFormFieldCompletionSubmitted();
                navigate('/unsubscribe-confirmed',
                    {
                        state: {
                            values: vals,
                            token: r.data.token
                        }
                    }
                );
            } else {
                navigate('/500');
            }
        })
        .catch((err) => {
            submitButton.classList.remove('button--submitting');
            navigate('/500');
        })
    }, [])

    const handleAllChecked = (event)=>{
        event.persist();
        if(event.target.name === 'unsubscribeAll'){
            setValues(values => ({ ...values, [event.target.name]: event.target.checked,  unsubscribeAuriniaAlliance: event.target.checked, unsubscribeDiseaseAwareness: event.target.checked, unsubscribeCorporate: event.target.checked}));
        }else{
            handleChange(event);
            setValues(values => ({ ...values, unsubscribeAll: false}));
        }
    }

    const valueChangeCallback = useCallback((vals) => {
        if(vals.unsubscribeAuriniaAlliance && vals.unsubscribeDiseaseAwareness && vals.unsubscribeCorporate && vals.unsubscribeAll === false){
            setValues(values => ({ ...values, unsubscribeAll: true}));
        }
    }, [])

    //GTM
    const gtmFormSuccess = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Form Submission',
            'Event_Category': 'Form Submissions',
            'Event_Action': 'Unsubscribe',
            'Event_Label': 'Complete'
        });
    }

    const gtmFormFieldError = (errors) => {
        if ( errors ) {
            for(var error in errors){
                const selector = ".error--" + error;
                if(document.querySelector(selector)){
                    const errorText = document.querySelector(selector).innerText;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'Form Errors',
                        'Event_Category': 'Validation Errors',
                        'Event_Action': 'Unsubscribe',
                        'Event_Label': error + '_' + errorText
                    });
                }

            }
        }
    }

    const gtmFieldCompletion = () => {
        document.querySelectorAll('.form--unsubscribe input').forEach( field => {
            if(!field.getAttribute('data-has-ga-listener')){
                field.setAttribute('data-has-ga-listener','true')
                field.addEventListener('change', fieldCompletionEvent);
            }
        });
    }

    const fieldCompletionEvent = function(e) {
        const field = e.target;
        let fieldInfo;
        switch(field.type){
            case ('radio'):
                fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                break;
            case ('checkbox'):
                fieldInfo = field.name + '_' + field.checked;
                break;
            default:
                fieldInfo = field.name
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Field Completion',
            'Event_Category': 'Form Field Completion',
            'Event_Action': 'Unsubscribe',
            'Event_Label': fieldInfo
        });
    }

    const gtmFormFieldCompletionSubmitted = () => {
        document.querySelectorAll('.form--unsubscribe input').forEach( field => {
            let fieldInfo;

            if ((field.type === 'radio' || field.type === 'checkbox') && !field.checked) return;
            if (field.value.length === 0) return;

            switch(field.type){
                case ('radio'):
                    fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                    break;
                case ('checkbox'):
                    fieldInfo = field.name + '_' + field.checked;
                    break;
                default:
                    fieldInfo = field.name
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event' : 'Form Field Completion_Form Submitted',
                'Event_Category': 'Form Field Completion_Form Submitted',
                'Event_Action': 'Unsubscribe',
                'Event_Label': fieldInfo
            });
        })
    }

    useEffect(()=>{
        gtmFieldCompletion();
    }, [])

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues
    } = useForm(success, validate, ruleSet.unsub.rules, {source: 'lupkynis_consumer_unsubscribe_2021', visitorId : getVisitorId()}, valueChangeCallback, gtmFormFieldError);


    return (
        <form className="form--unsubscribe" onSubmit={handleSubmit}>
            <div className="single-col-input">
                <label>
                    Email Address*:
                    <input type="text" name="email" placeholder="e.g. jsmith@email.com" value={values.email || ''} onChange={handleChange} aria-invalid={errors.email ? true : false} maxLength={'50'}/>
                </label>
                {errors.email && (
                    <p className="error error--email">Please provide your email address</p>
                )}
            </div>
            <p>Select any other marketing communications you're no longer interested in receiving:</p>
            <div className="checkbox-wrapper">
                <input name="unsubscribeAuriniaAlliance" id="unsubscribeAuriniaAllianceCheckbox" type="checkbox" checked={values.unsubscribeAuriniaAlliance} onChange={handleAllChecked} />
                <label htmlFor="unsubscribeAuriniaAllianceCheckbox">Marketing communications about patient support from <span className="nowrap">Aurinia&nbsp;Alliance<sup>&reg;</sup></span></label>
            </div>
            <div className="checkbox-wrapper">
                <input name="unsubscribeDiseaseAwareness" id="unsubscribeDiseaseAwarenessCheckbox" type="checkbox" checked={values.unsubscribeDiseaseAwareness} onChange={handleAllChecked} />
                <label htmlFor="unsubscribeDiseaseAwarenessCheckbox">Disease state awareness information</label>
            </div>
            <div className="checkbox-wrapper">
                <input name="unsubscribeCorporate" id="unsubscribeCorporateCheckbox" type="checkbox" checked={values.unsubscribeCorporate} onChange={handleAllChecked} />
                <label htmlFor="unsubscribeCorporateCheckbox">Corporate communications from Aurinia, the makers of LUPKYNIS</label>
            </div>
            <div className="checkbox-wrapper">
                <input name="unsubscribeAll" id="unsubscribeAllCheckbox" type="checkbox" checked={values.unsubscribeAll} onChange={handleAllChecked} />
                <label htmlFor="unsubscribeAllCheckbox">All Aurinia-affiliated communications</label>
            </div>
            <p>NOTE: If you are currently enrolled in Aurinia Alliance, you may still receive communications from your Nurse Case Manager or the program. If you have any questions about your enrollment, please call <Anchor url="tel:18332874642">1-833-AURINIA (1-833-287-4642)</Anchor>.</p>
            <button type="submit" className="button button--unsubscribe">Unsubscribe</button>
        </form>
    )
}

export default Unsubscribe;
